<template>
  <div
    v-if="$can('read', 'Content')"
    style="height: inherit"
  >
    <image-light-box
      ref="refImageLightBox"
      :show-light-box="false"
      :show-caption="true"
      :show-thumbs="false"
      :disable-zoom="false"
      :media="mediaLightBox"
    />
    <b-card
      no-body
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <b-card-body style="margin: 0px 0px -10px 0px; padding: 20px 35px 0px 20px;">
          <div>
            <b-row>
              <!-- Field: Page -->
              <b-col
                cols="12"
              >
                <b-form-group
                  :label="$t('Page')"
                  label-for="content-page"
                >
                  <v-select
                    id="content-page"
                    v-model="pageFilter"
                    :options="contentData.pageOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :placeholder="$t('Page')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

        </b-card-body>

        <!-- Table Container Card -->
        <b-card
          no-body
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <label>{{ $t('Entries') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                />
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                sm="12"
                md="6"
              >
                <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                  <b-button
                    v-if="$can('create', 'Content')"
                    variant="primary"
                    class="w-50"
                    @click="addContentForm"
                  >
                    {{ `${$t('Add')} ${$t('Content')}` }}
                  </b-button>
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1 ml-1"
                    :placeholder="`${$t('Search')}...`"
                  />
                </div>
              </b-col>
            </b-row>

          </div>
          <b-table
            ref="refContentTable"
            :items="getListContent"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            hover
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
            selectable
            select-mode="multi"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="tableColumnStyle(field.key)"
              >
            </template>

            <!-- Column: Page/Sort/Status/Id -->
            <template #cell(page)="data">
              <!-- Page -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Page') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-primary"
                      >
                        {{ data.item.page_name }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- Sort -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Sort') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-primary"
                      >
                        {{ data.item.sort }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <!-- Status -->
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('Status') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        :variant="`light-${isStatusVariant(data.item.status)}`"
                        class="text-capitalize"
                      >
                        {{ $t(isStatusToText(data.item.status)) }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="margin-right: 0.6rem;">
                      {{ $t('ID') }} »
                    </span>
                    <span style="font-size: 14px;">
                      <b-badge
                        pill
                        variant="light-info"
                        class="text-capitalize"
                      >
                        {{ data.item.id }}
                      </b-badge>
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Image -->
            <template #cell(image)="data">
              <!-- Image -->
              <div style="margin-top: 0.3rem;">
                <b-link
                  class="nav-link"
                  @click="showPopupImage(data.item)"
                >
                  <b-badge
                    variant="light-info"
                    style="padding: 8px 15px 8px 15px;"
                  >
                    <b-img
                      :src="data.item.image_url"
                      style="height: 50px;"
                    />
                  </b-badge>
                </b-link>
              </div>
            </template>

            <!-- Column: Title/Content -->
            <template #cell(title)="data">
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      {{ data.item.title }}
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
              <div style="margin-top: 0.3rem;">
                <b-list-group>
                  <b-list-group-item
                    style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                  >
                    <span style="font-size: 14px;">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <span v-html="data.item.content" />
                    </span>
                  </b-list-group-item>
                </b-list-group>
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">

              <div class="text-nowrap">
                <a
                  class="action-icon"
                  @click.stop="editContentForm(data)"
                >
                  <feather-icon
                    v-if="$can('write', 'Content')"
                    :id="`content-row-${data.item.id}-edit-icon`"
                    icon="EditIcon"
                    class="cursor-pointer mr-1"
                    size="16"
                  />
                  <b-tooltip
                    v-if="$can('write', 'Content')"
                    :title="`${$t('Edit')} ${$t('Content')}`"
                    :target="`content-row-${data.item.id}-edit-icon`"
                    :delay="{ show: 2500, hide: 50 }"
                    placement="left"
                  />
                </a>

                <a
                  class="action-icon"
                  @click.stop="viewContentForm(data)"
                >
                  <feather-icon
                    v-if="$can('read', 'Content')"
                    :id="`content-row-${data.item.id}-view-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="cursor-pointer mr-1"
                  />
                  <b-tooltip
                    v-if="$can('read', 'Content')"
                    :title="`${$t('View')} ${$t('Content')}`"
                    :target="`content-row-${data.item.id}-view-icon`"
                    :delay="{ show: 2500, hide: 50 }"
                    placement="left"
                  />
                </a>

                <a
                  class="action-icon"
                  @click.stop="deleteContentForm(data)"
                >
                  <feather-icon
                    v-if="$can('delete', 'Content')"
                    :id="`content-row-${data.item.id}-delete-icon`"
                    icon="TrashIcon"
                    variant="light-success"
                    size="16"
                    class="cursor-pointer mr-1"
                  />
                  <b-tooltip
                    v-if="$can('delete', 'Content')"
                    :title="`${$t('Delete')} ${$t('Content')}`"
                    :target="`content-row-${data.item.id}-delete-icon`"
                    :delay="{ show: 2500, hide: 50 }"
                    placement="right"
                  />
                </a>

              </div>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">
                  {{ $t('Showing {from} to {to} of {of} entries',
                        {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                  }}
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalContents"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

        <content-form
          v-model="shallShowContentFormModal"
          :content-data="contentData"
          :type-content-form="typeContentForm"
          @content-form-update="updateContentForm"
          @discard-content-form="discardContentForm"
        />
      </b-overlay>
    </b-card></div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
  BLink,
  BImg,
  BFormGroup,
  BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ImageLightBox from 'vue-image-lightbox'
import tableContent from './tableContent'
import storeContent from './storeContent'
import ContentForm from './ContentForm.vue'

export default {
  components: {
    ContentForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,
    BLink,
    BImg,
    BFormGroup,
    BCardBody,

    vSelect,
    FeatherIcon,
    ImageLightBox,
  },
  data() {
    return {
      showOverlay: false,
      contentData: {},
      dialog: false,
      selected: [],
      checkSelected: false,
      collapsedFilter: true,
      isShowLightBox: false,
      mediaLightBox: [{}],
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultContent()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultContent() {
      this.showOverlay = true
      store
        .dispatch('store-content/getDefaultContent', { language: this.$i18n.locale, pageFilter: this.pageFilter })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contentData = response.data
          if (this.pageFilter === '') {
            this.pageFilter = this.contentData.pageOptions[0].value
          }
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addContentForm() {
      this.showOverlay = true
      store
        .dispatch('store-content/getDefaultContent', { language: this.$i18n.locale, pageFilter: this.pageFilter })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contentData = response.data
          this.contentData.page = this.pageFilter
          this.contentData.image_data = ''
          this.contentData.image_url = ''
          this.typeContentForm = 'Add'
          this.shallShowContentFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editContentForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-content/getContent', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contentData = response.data
          this.contentData.image_data = ''
          this.typeContentForm = 'Edit'
          this.shallShowContentFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewContentForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-content/getContent', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.contentData = response.data
          this.contentData.image_data = ''
          this.typeContentForm = 'View'
          this.shallShowContentFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteContentForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-content/deleteContent', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateContentForm() {
      this.refetchData()
    },
    discardContentForm() {
      this.refetchData()
    },
    showPopupImage(data) {
      const image = [
        {
          src: data.image_url,
          caption: data.name,
        },
      ]
      this.mediaLightBox = image
      this.$refs.refImageLightBox.showImage(0)
    },
  },
  setup() {
    const shallShowContentFormModal = ref(false)
    const typeContentForm = ref('')

    if (!store.hasModule('store-content')) store.registerModule('store-content', storeContent)
    onUnmounted(() => {
      if (store.hasModule('store-content')) store.unregisterModule('store-content')
    })

    const {
      getListContent,
      tableColumns,
      tableColumnStyle,
      perPage,
      currentPage,
      totalContents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      pageFilter,
      refContentTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
    } = tableContent()

    const filterList = JSON.parse(localStorage.getItem('filterContent'))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
      pageFilter.value = (filterList.pageFilter !== null && filterList.pageFilter !== undefined) ? filterList.pageFilter : ''
    }

    return {
      shallShowContentFormModal,
      typeContentForm,

      getListContent,
      tableColumns,
      tableColumnStyle,
      perPage,
      currentPage,
      totalContents,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      pageFilter,
      refContentTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.content-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/tools/sharetarget.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #429dc8 !important;
  }
}
</style>
